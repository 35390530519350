import { call, put, takeLatest } from 'redux-saga/effects'
import * as Constants from '../actions/constants'
import * as ParseApi from '../apis/parse'

export function* getPackageListSaga(action) {
  try {
    const payload = yield call(ParseApi.getPackageList)
    yield put({ type: Constants.PACKAGE_LIST_GET_SUCCESS, payload })
  } catch (err) {
    yield put({ type: Constants.PACKAGE_LIST_GET_FAILED, err })
  }
}

export function* watchGetPackageListSaga() {
  yield takeLatest(Constants.PACKAGE_LIST_GET, getPackageListSaga)
}
