import * as Constants from '../../actions/constants'

const initialState = {
  loading: false,
  success: false,
}

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.LOCATION_CHANGE: return initialState;
    case Constants.RESET_PASSWORD_POST:
      return {
        ...state,
        loading: true,
        success: false,
      }
    case Constants.RESET_PASSWORD_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      }
    case Constants.RESET_PASSWORD_POST_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
      }
    default:
      return state
  }
}
