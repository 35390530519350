import { ACTIVATE_EXPERIMENT, DEACTIVATE_EXPERIMENT } from '../actions/constants'

export default function(state = {}, action = {}) {
  switch (action.type) {
    case ACTIVATE_EXPERIMENT:
      return Object.assign({}, state.experiment, { [action.experimentName]: action.value })
    case DEACTIVATE_EXPERIMENT:
      return Object.assign({}, state.experiment, { [action.experimentName]: false })
    default:
      return state
  }
}
