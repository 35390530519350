import React, { Suspense, memo, lazy, useEffect } from 'react'
import { connect } from 'react-redux'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { locationChange } from './actions/redirect'
import RouterHistory from './history'

const AppLogin = lazy(() => import('./containers/AppLogin'))
const AppRequest = lazy(() => import('./containers/AppRequest'))
const NotFoundPage = lazy(() => import('./containers/NotFoundPage'))
const LoginPage = lazy(() => import('./containers/LoginPage'))
const ResetPasswordPage = lazy(() => import('./containers/ResetPasswordPage'))
const RegisterPage = lazy(() => import('./containers/RegisterPage'))
const TermsPage = lazy(() => import('./containers/TermsPage'))
const PrivacyPolicyPage = lazy(() => import('./containers/PrivacyPolicyPage'))
const RequestServicePage = lazy(() => import('./containers/RequestServicePage'))
const SubscriptionPage = lazy(() => import('./containers/SubscriptionPage'))
const SubscriptionHistoryPage = lazy(() => import('./containers/SubscriptionHistoryPage'))
const SubscriptionPayPage = lazy(() => import('./containers/SubscriptionPayPage'))
const InvitePage = lazy(() => import('./containers/InvitePage'))
const HistoryJobPage = lazy(() => import('./containers/HistoryJobPage'))
const CompletedJobPage = lazy(() => import('./containers/CompletedJobPage'))
const InprogressJobPage = lazy(() => import('./containers/InprogressJobPage'))
const SettingPage = lazy(() => import('./containers/SettingPage'))
const RequestMaidPage = lazy(() => import('./containers/RequestMaidPage'))
const RequestDogSitterPage = lazy(() => import('./containers/RequestDogSitterPage'))
const RequestAcPage = lazy(() => import('./containers/RequestAcPage'))
const RequestElderPage = lazy(() => import('./containers/RequestElderPage'))
const RequestNannyPage = lazy(() => import('./containers/RequestNannyPage'))
const RequestRepairerPage = lazy(() => import('./containers/RequestRepairerPage'))
const JobDetailPage = lazy(() => import('./containers/JobDetailPage'))
const LogoutPage = lazy(() => import('./containers/LogoutPage'))

const PaymentMethod = lazy(() => import('./components/PaymentMethod'))

const PrivateRoute = memo(({ session, ...rest }) => {
  const redirect = (location) => {
    if (location) {
      if (location.pathname === '/logout') return '/'
    }
    return location
  }
  return session.user && session.user.customer_id ? (
    <Route {...rest} />
  ) : (
    <Redirect to={{
      pathname: '/login',
      state: { from: redirect(rest.location) }
    }} />
  )
})

const ByPassAuthenRoute = memo(({ session, ...rest }) => {
  const pathname = (() => {
    if (!rest.location.state || !rest.location.state.from) return '/'
    if (['/login', '/register', '/resetPassword'].includes(rest.location.state.from.pathname)) return '/'
    return rest.location.state.from.pathname
  })()
  return session.user && session.user.customer_id ? <Redirect to={{ pathname }} />
  : <Route {...rest} />
})

const SuspenseRoute = memo((props) => {
  return <Suspense fallback={<div/>}><Route {...props}/></Suspense>
})

const AppRoot = ({ locationChange, ...session }) => {
  useEffect(() => {
    return RouterHistory.currentHistory.listen(locationChange)
  }, [])
  return (
    <Router history={RouterHistory.currentHistory}>
      <Suspense fallback={<div/>}>
        <Switch>
          <SuspenseRoute exact path="/terms_of_service" component={TermsPage}/>
          <SuspenseRoute exact path="/terms_of_service_subscription" component={TermsPage}/>
          <SuspenseRoute exact path="/privacy_policy" component={PrivacyPolicyPage}/>
          <ByPassAuthenRoute exact path={['/login', '/register', '/resetPassword']} session={session} >
            <Suspense fallback={<div/>}>
              <AppLogin>
                <Switch>
                  <SuspenseRoute exact path="/login" component={LoginPage} />
                  <SuspenseRoute exact path="/register" component={RegisterPage} />
                  <SuspenseRoute exact path="/resetPassword" component={ResetPasswordPage} />
                </Switch>
              </AppLogin>
            </Suspense>
          </ByPassAuthenRoute>
          <PrivateRoute session={session}>
            <Suspense fallback={<div/>}>
              <AppRequest>
                <Switch>
                  <SuspenseRoute exact path="/" component={RequestServicePage}/>
                  <SuspenseRoute exact path="/job/:jobId" component={JobDetailPage}/>
                  <SuspenseRoute exact path="/maid" component={RequestMaidPage}/>
                  <SuspenseRoute exact path="/dogsitter" component={RequestDogSitterPage}/>
                  <SuspenseRoute exact path="/ac" component={RequestAcPage}/>
                  <SuspenseRoute exact path="/elder" component={RequestElderPage}/>
                  <SuspenseRoute exact path="/nanny" component={RequestNannyPage}/>
                  <SuspenseRoute exact path="/repairer" component={RequestRepairerPage}/>
                  <SuspenseRoute exact path="/subscription" component={SubscriptionPage}/>
                  <SuspenseRoute exact path="/subscription/history" component={SubscriptionHistoryPage}/>
                  <SuspenseRoute exact path="/subscription/:id" component={SubscriptionPayPage}/>
                  <SuspenseRoute exact path="/invite" component={InvitePage}/>
                  <SuspenseRoute exact path="/settings" component={SettingPage}/>
                  <SuspenseRoute exact path="/payment" component={PaymentMethod}/>
                  <SuspenseRoute exact path="/logout" component={LogoutPage}/>
                  <SuspenseRoute exact path={['/history/inprogress', '/history/completed']}>
                    <Suspense fallback={<div/>}>
                      <HistoryJobPage>
                        <Switch>
                          <SuspenseRoute exact path="/history/inprogress" component={InprogressJobPage}/>
                          <SuspenseRoute exact path="/history/completed" component={CompletedJobPage}/>
                        </Switch>
                      </HistoryJobPage>
                    </Suspense>
                  </SuspenseRoute>
                  <Redirect from="/history" to="/history/inprogress" />
                  <Redirect from="/job" to="/" />
                </Switch>
              </AppRequest>
            </Suspense>
          </PrivateRoute>
          <SuspenseRoute component={NotFoundPage}/>
        </Switch>
      </Suspense>
    </Router>
  )
}

export default connect(state => state.session, { locationChange })(memo(AppRoot))
