import { call, put, takeLatest } from 'redux-saga/effects'
import * as Constants from '../actions/constants'
import * as ParseApi from '../apis/parse'
// import { setUser } from '../actions/session'
import { message } from 'antd'
// import { removeSession, saveSession } from '../utils/cookie'

export function* resetPasswordPostSaga(action) {
  try {
    yield call(ParseApi.requestPasswordReset, action.email)
    yield put({ type: Constants.RESET_PASSWORD_POST_SUCCESS })
    yield call(message.success, 'ระบบได้ส่งลิ้งไปยังอีเมลเรียบร้อย')
  } catch (err) {
    yield put({ type: Constants.RESET_PASSWORD_POST_FAILED, err })
  }
}

export function* watchPostResetPassword() {
  yield takeLatest(Constants.RESET_PASSWORD_POST, resetPasswordPostSaga)
}

// export function* loginRedirect() {
//   const { pages: { login } } = yield select()
//   if (login.redirectTo) {
//     yield put(redirectTo(login.redirectTo))
//     yield put(clearRedirectUrl())
//   } else {
//     yield put(replaceToHome())
//   }
// }

// export function* loginFacebookSaga(action) {
//   try {
//     const payload = yield call(ParseApi.loginFacebook)
//     yield put({ type: Constants.LOGIN_FACEBOOK_SUCCESS, payload })
//     yield call(saveSession, payload)
//     yield fork(loginRedirect)
//   } catch (err) {
//     yield put({ type: Constants.LOGIN_FACEBOOK_FAILED, err })
//   }
// }

// export function* watchLoginFacebook() {
//   yield takeLatest(Constants.LOGIN_FACEBOOK, loginFacebookSaga)
// }

// export function* signUpEmailSaga(action) {
//   try {
//     const payload = yield call(ParseApi.signUpEmail, action.email, action.password, action.nickname)
//     yield put({ type: Constants.SIGNUP_EMAIL_SUCCESS, payload })
//     yield call(saveSession, payload)
//     yield fork(loginRedirect)
//   } catch (err) {
//     yield put({ type: Constants.SIGNUP_EMAIL_FAILED, err })
//   }
// }

// export function* watchSignUpEmail() {
//   yield takeLatest(Constants.SIGNUP_EMAIL, signUpEmailSaga)
// }

// export function* loginEmailSaga(action) {
//   try {
//     const payload = yield call(ParseApi.loginEmail, action.email, action.password)
//     yield put({ type: Constants.LOGIN_EMAIL_SUCCESS, payload })
//     yield call(saveSession, payload)
//     yield fork(loginRedirect)
//   } catch (err) {
//     yield put({ type: Constants.LOGIN_EMAIL_FAILED, err })
//   }
// }

// export function* watchLoginEmail() {
//   yield takeLatest(Constants.LOGIN_EMAIL, loginEmailSaga)
// }

// export function* logoutSaga() {
//   yield call(ParseApi.logout)
//   yield call(removeSession)
//   yield put(replaceToHome())
// }

// export function* watchLogout() {
//   yield takeLatest(Constants.LOGOUT, logoutSaga)
// }
