import { combineReducers } from 'redux'
import { RECEIVE_REVIEWS, RECEIVE_REVIEW } from '../actions/constants'

export default combineReducers({
  allIds,
  byId,
})

function allIds(state = [], action = {}) {
  switch (action.type) {
    case RECEIVE_REVIEW:
      if (!state.includes(action.review.id)) {
        return [...state, action.review.id]
      }
      return state
    case RECEIVE_REVIEWS:
      const array = [...state]
      action.reviews.forEach(review => {
        if (!array.includes(review.id)) {
          array.push(review.id)
        }
      })
      return array
    default:
      return state
  }
}

function byId(state = {}, action = {}) {
  switch (action.type) {
    case RECEIVE_REVIEW:
      return Object.assign({}, state, {
        [action.review.id]: action.review
      })
    case RECEIVE_REVIEWS:
      return Object.assign({}, state,
        action.reviews.reduce((obj, review) => {
          obj[review.id] = review
          return obj
        }, {})
      )
    default:
      return state
  }
}
