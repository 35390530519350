import * as Constants from '../actions/constants'

const initialState = {
  user: null,
  activeJob: 0,
  customer: null,
  config: null,
}

export default function(state = initialState, action = {}) {
  switch (action.type) {
    // case RECEIVE_JOBS:
    //   return Object.assign({}, state, {
    //     activeJob: action.jobs.filter(job => job.get('status').match(/waiting|match/)).length,
    //   })

    case Constants.CREDIT_CARD_POST_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          default_creditcard_id: action.payload
        },
      }
    case Constants.CREDIT_CARD_SELECT_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.payload,
          default_creditcard_id: action.payload.payment_method === 'omise' ? action.payload.default_creditcard_id : null,
        },
      }
    case Constants.RECEIVE_ACTIVE_JOB:
      return Object.assign({}, state, {
        activeJob: action.activeJob,
      })
    case Constants.INCREMENT_ACTIVE_JOB:
      return Object.assign({}, state, {
        activeJob: state.activeJob + 1,
      })
    case Constants.IS_LOGIN:
      return Object.assign({}, state, {
        user: action.user,
        customer: action.user.customer_id,
      })
    case Constants.IS_LOGOUT:
      return Object.assign({}, state, {
        user: null,
        customer: null,
      })
    case Constants.REQUEST_LOGIN_FAIL:
      return Object.assign({}, state, {
        user: null,
        error: action.error
      })
    case Constants.SET_CONFIG:
      return Object.assign({}, state, {
        config: action.config,
      })
    default:
      return state
  }
}
