import { fork, all } from 'redux-saga/effects'
import * as Session from './session'
import * as CreditCards from './creditCards'
import * as Subscriptions from './subscriptions'
import * as PackageList from './packageList'

export default function* rootSaga() {
  yield all([
    // Session
    fork(Session.watchPostResetPassword),
    // fork(Session.watchLoginFacebook),
    // fork(Session.watchLoginEmail),
    // fork(Session.watchSignUpEmail),
    // fork(Session.watchLogout),

    //CreditCards
    fork(CreditCards.watchGetCreditCards),
    fork(CreditCards.watchGetCreditCard),
    fork(CreditCards.watchPostCreditCard),
    fork(CreditCards.watchSelectCreditCard),

    // Subscriptions
    fork(Subscriptions.watchCreateSubscriptionRequest),

    // PackageList
    fork(PackageList.watchGetPackageListSaga),
  ]);
}
