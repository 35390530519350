import { call, put, takeLatest } from 'redux-saga/effects'
import * as Constants from '../actions/constants'
import * as ParseApi from '../apis/parse'
import { replaceToSubscriptionPayment } from '../actions/redirect'
import { message } from 'antd'

export function* createSubscriptionRequestSaga(action) {
  try {
    const payload = yield call(ParseApi.requestSubscription, action.packageListId, action.months, action.creditCard)
    yield put({ type: Constants.SUBSCRIPTION_REQUEST_CREATE_SUCCESS, payload })
    yield call(replaceToSubscriptionPayment)
    yield call(message.success, 'Success')
  } catch (err) {
    yield put({ type: Constants.SUBSCRIPTION_REQUEST_CREATE_FAILED, err })
  }
}

export function* watchCreateSubscriptionRequest() {
  yield takeLatest(Constants.SUBSCRIPTION_REQUEST_CREATE, createSubscriptionRequestSaga)
}

// export function* postCreditCard(action) {
//   try {
//     const token = yield call(createToken, action.name, action.number, action.expirationMonth, action.expirationYear, action.cvc)
//     const payload = yield call(ParseApi.postCreditCard, token.id)
//     yield put(hideCreditCardForm())
//     yield put({ type: Constants.CREDIT_CARD_POST_SUCCESS, payload })
//   } catch (err) {
//     yield put({ type: Constants.CREDIT_CARD_POST_FAILED, err })
//   }
// }
//
// export function* watchPostCreditCard() {
//   yield takeLatest(Constants.CREDIT_CARD_POST, postCreditCard)
// }
//
// export function* selectCreditCard(action) {
//   try {
//     const payload = yield call(ParseApi.selectCreditCard, action.creditCardId)
//     yield put({ type: Constants.CREDIT_CARD_SELECT_SUCCESS, payload, creditCardId: action.creditCardId })
//   } catch (err) {
//     yield put({ type: Constants.CREDIT_CARD_SELECT_FAILED, err })
//   }
// }
//
// export function* watchSelectCreditCard() {
//   yield takeLatest(Constants.CREDIT_CARD_SELECT, selectCreditCard)
// }
//
// export function* getCreditCard(action) {
//   try {
//     const payload = yield call(ParseApi.getCreditCard, action.id)
//     yield put({ type: Constants.CREDIT_CARD_GET_SUCCESS, payload })
//   } catch (err) {
//     yield put({ type: Constants.CREDIT_CARD_GET_FAILED, err })
//   }
// }
//
// export function* watchGetCreditCard() {
//   yield takeLatest(Constants.CREDIT_CARD_GET, getCreditCard)
// }
