import { INIT_LANGUAGE } from './constants'
import { defaultLang } from '../locales'
import RouterHistory from '../history'

export function changeLanguage(locale) {
  window.location.replace(`/${locale}${RouterHistory.currentHistory.location.pathname}${RouterHistory.currentHistory.location.search}`)
}

export function initLanguage() {
  return (dispatch, getState) => {
    const { language } = getState()
    const currentLang = language.allLocales.find(locale => window.location.pathname.includes(locale))
    if (!currentLang) {
      RouterHistory.create(defaultLang)
      window.location.replace(`/${defaultLang}${RouterHistory.currentHistory.location.pathname}${RouterHistory.currentHistory.location.search}`)
    } else {
      RouterHistory.create(currentLang)
      dispatch({ type: INIT_LANGUAGE, locale: currentLang })
    }
  }
}
