import { INIT_REFERRAL, PROMOTION_APPLY_SUCCESS, PROMOTION_GET_SUCCESS, CLEAR_PROMO_CODE, CLEAR_REFERRAL, LOCATION_CHANGE } from '../actions/constants'

export default function(state = {
  promoCode: null,
  referralCode: ''
}, action = {}) {
  switch (action.type) {
    case LOCATION_CHANGE: return {
      ...state,
      promoCode: null,
    }
    case PROMOTION_GET_SUCCESS:
    case PROMOTION_APPLY_SUCCESS:
      return {
        ...state,
        promoCode: action.promoCode,
      }
    case INIT_REFERRAL:
      return {
        ...state,
        referralCode: action.referralCode,
      }
    case CLEAR_PROMO_CODE:
    case CLEAR_REFERRAL:
    default:
      return state
  }
}
