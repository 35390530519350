import * as Constants from './constants'
import * as Api from '../apis/parse'

export const initReferral = referralCode => ({
  type: Constants.INIT_REFERRAL,
  referralCode,
})

export const clearReferral = () => ({
  type: Constants.CLEAR_REFERRAL,
})

export const applyPromo = (promoCode, jobType) => {
  return dispatch => {
    return Api.applyPromo(promoCode, jobType).then(promoCode => {
      dispatch({ type: Constants.PROMOTION_APPLY_SUCCESS, promoCode })
    })
  }
}

export const getPromoForJob = (jobType) => {
  return dispatch => {
    return Api.findPromo(jobType).then(promoCode => {
      dispatch({ type: Constants.PROMOTION_GET_SUCCESS, promoCode })
    }).catch(err => {
      console.log(err);
      
    })
  }
}

export const clearPromoCode = () => ({
  type: Constants.CLEAR_PROMO_CODE,
})
