export default {
  "english": "Eng",
  "thai": "ไทย",
  'language': 'Language',
  "seo-title": "Ayasan | Cleaning/Babysitting Service By 1 Click in Bangkok, Thailand  - On-Demand / Subscription",
  "seo-description": "Cleaning/Babysitting/Elder-Care Service By 1 Click.  150 Baht  + / hour.  Others-Repairing/AC Cleaning in Bangkok,Thailand",
  "seo-keywords": "cleaning service Bangkok, maid service Bangkok, nanny service Thailand, housekeeping Bangkok, maid nanny Thailand",
  "title":"“Maid Service By 1 Click”",
  "subtitle":"Help around home is by few clicks on App.",
  "already-member":"Already a member?",
  "how-work":"How Ayasan Works?",
  "send-request":"Send Request",
  "signup":"SIGN UP",
  "send-request-desc":"This will help you describe which best for your job. Today booking is okay.",
  "choose-helper":"Choose Helper",
  "choose-helper-desc":"Our professional / friendly helper will come to you place.",
  "life-easy":"Make Your Life Easy",
  "life-easy-desc":"Your task is complete to your satisfaction guarantee.",
  "just-find-your":"Just find your",
  "just-find-your-2":"Helper around your place.",
  "promote-users": "More than 20,000 users in Bangkok are already using Ayasan App!",
  "why-our":"Why our",
  "why-our-2":"Service?",
  maid:"Maid",
  nanny:"Nanny",
  messenger:"Messenger",
  tutor:"English tutor",
  elder:"Elderly care",
  "dogsitter":"Dog Sitter",
  "repairer":"Repairer",
  time:"Time",
  "time-desc":"Saving your time from routine job.",
  support:"Support",
  "support-desc":"We will provide customer help 24 hours.",
  quality:"Quality",
  "quality-desc":"Every helper pass our review.",
  payment:"Payment Easy",
  "payment-desc":"Just using your credit card don’t need cash.",
  tech:"Technology",
  "tech-desc":"Our product supported by latest technology.",
  "ayasan-job-desc":"Want to earn money with Ayasan?",
  "ayasan-job-signup":"Sign up here",
  signin:"SIGN IN",
  username:"Email",
  password:"Password",
  remember:"remember",
  "forget-password":"forgot your password? ",
  "click-here":"click here ",
  "thank-you":"Thank you for your interest in Ayasan Service,",
  "download":"You can download the app on ",
  "download-easy":"Download app for more convenient",
  //"our-company":"Our ",
  "our-company-2":"Company",
  "learn-more":"Learn more",
  "download-now":"Download now!",
  "review-section-title":"Our customer loves our service",
  "ac":"AC cleaner",
  logout: 'Logout',
  "login-facebook": 'CONNECT WITH FACEBOOK',
  "register-facebook": 'CONNECT WITH FACEBOOK',
  "register-email": 'Register with email',
  "request-service": 'Request',
  "request-service-loading": 'Requesting...',
  hour: 'hour',
  unit: 'unit',
  units: 'units',
  "menu-request-service": 'Request Service',
  "setting-payment": 'Payment',
  "edit-profile": 'Edit profile',
  "terms-of-use": 'Terms of use',
  "privacy-policy": 'Privacy policy',
  "job-list": 'Job list',
  address: 'Address',
  "schedule-at": 'Schedule at',
  "add-address": 'Add home address',
  "add-phone": 'Add phone number',
  floors: 'Floors',
  rooms: 'Rooms',
  "working-hours": 'Working Hours',
  hours: 'hours',
  cleaning: 'Cleaning',
  "speak-english": 'Speak English',
  iron: 'Iron',
  laundry: 'Laundry',
  cash: 'Cash',
  promo: 'Promotion',
  estimate: 'Price:',
  save: 'Save',
  'saving': 'Saving',
  'update-address': 'Please update your address',
  'your-address': 'Street, province and city',
  'address-number': 'Address number',
  'room-number': 'Room number',
  'room-number-error': 'Please provide your room no.',
  'address-number-error': 'Please provide your address no.',
  'condo': 'Condo',
  'apartment': 'Apartment',
  'house': 'House',
  "address-error": 'Please enter a valid address',
  "phone-error": 'Please enter a valid phone number',
  "date-error": 'Please enter a valid date',
  "child-age": 'Child age',
  'dog-size': 'Dog size',
  "promo-error": 'Please enter promo code',
  "apply-promo-success": 'Apply promo code success',
  'apply': 'Apply',
  "enter-promo": 'Enter promo code',
  'ac-no-title': 'No. of AC unit',
  'ac-type-title': 'AC type',
  'ac-btu': 'BTU',
  'ac-btu-over': 'Over',
  'ac-btu-not-over': 'Not over',
  'ac-not-specify': 'Not specify',
  'repairer-desc-title': 'Please describe what you want to be fixed',
  'description': 'Description',
  'ac-wall': 'Wall type',
  'ac-floor': 'Floor type',
  'ac-ceiling': 'Ceiling type',
  'and': 'and',
  'signup-terms': 'By clicking sign up you accept our',
  'first-name': 'First name',
  'last-name': 'Last name',
  'phone-number': 'Phone number',
  'email': 'Email',
  'create-account': 'CREATE ACCOUNT',
  'sign-up-free': 'Sign up for free',
  'back-to': 'Back to',
  'dont-have-account': 'Don\'t have an account?',
  'write-comment': 'Write a comment here...',
  'rate-satisfaction': 'Please rate your satisfaction',
  'tap-change-image': 'Tap to change image',
  'no-inprogress': 'You have no in progress job.',
  'no-inprogress-request': 'Our helper are ready to help, request now.',
  'status-waiting': 'WAITING',
  'status-cancel': 'CANCELLED',
  'status-match': 'MATCHED',
  'status-done': 'COMPLETED',
  'customers': 'Customers',
  'rating': 'Rating',
  'reviews': 'Reviews',
  'price': 'Price',
  'job-detail': 'Job detail',
  'confirm-modal-title': 'You are going to request Ayasan service',
  'confirm-modal-context': 'We will contact you in a few moments. Confirm your request?',
  'confirm-modal-ok': 'Confirm Request',
  'inprogress': 'Upcoming',
  'completed': 'Completed',
  'no-completed': 'No job history',
  'wait-supporter': 'Waiting for supporter...',
  'price-hour': 'Price per hour',
  'price-unit': 'Price per unit',
  'total-hour': 'Total hour',
  'total-unit': 'Total unit',
  'total': 'Total',
  'charged': 'CHARGED',
  'signup-title': 'Sign up to request service',
  'signin-title': 'Sign in to request service',
  'intro-request-service': 'Request service',
  'login': 'Login',
  'request-from-web': 'Continue request from website',
  'or': 'or',
  'maid-service': 'Maid service',
  'review-section-1': 'Our commitment for your satisfaction',
  'review-section-2': 'We are actively improve our service and value your feedback',
  'intro-1': 'We are expert in maid, nanny, ac clean and repair service',
  'intro-2': 'With our expertise, we assure you our experienced with our well-trained helpers',
  'intro-2-safe': '100% Guarantee for damage / lost problem',
  'intro-3': '100% money back guarantee\nif not satisfy with our service',
  'intro-button': 'See all services',
  'our-service': 'Our services',
  'our-service-2': 'Service quality is our primary concern',
  'refund': '100% REFUND',
  'select-service': 'Select service',
  'how-to-1': 'Request service within few steps',
  'how-to-2': 'Select the service you are in need',
  'how-to-3': 'Fill you information',
  'how-to-4': 'Just fill in your information of what you want to be taken cares of',
  'how-to-5': 'Send to us',
  'how-to-6': 'Finish you information and send your request to us. We will contact you as soon as possible',
  'mobile-app-1': 'Now available in your smart phone. Your personal services within your finger tips.',
  'more-1': 'Still looking for helper?',
  'more-2': 'If you still not sure about services,',
  'more-3': 'would like to help you. With our expertise, we guarantee you our quality and well-trained helper',
  'our-company': 'Our company',
  'startup-community': 'We joined Start Up community',
  'contact-us': 'Contact us',
  'contact-us-fb': 'Follow us on Facebook',
  'contact-us-line': 'Contact us via Line',
  'maid-detail-1': 'House cleaning service\ncondominium / dorm including laundry',
  'nanny-detail-1': 'Baby sitter service including\nfeeding showering',
  'ac-detail-1': 'A/C cleaning service on both\nsmall / big size',
  'repairer-detail-1': 'Fix broken item including\nelectric work / plumbing work',
  'get-inquiry': 'Free inquiry',
  'faq': 'FAQ',
  'faq-full': 'Frequency ask question',
  'faq-q1': 'Do you provide the cleaning supplies?',
  'faq-q2': 'Can you guarantee the service quality?',
  'faq-q3': 'Can we ask her to go shopping?',
  'faq-q4': 'How many minimum service hours?',
  'faq-q5': 'If the helper arrive late, can me extend the time to cover the time?',
  'faq-q6': 'Can we extend the schedule?',
  'faq-q7': 'Do I need to instruct what I need?',
  'faq-q8': 'Can I leave the helper in my room alone?',
  'faq-q9': 'If something problem happens with your helper, what should we do?',
  'faq-q10': 'Do your company holds identification for each helper?',
  'faq-q11': 'Can we hire the helper directly?',
  'faq-a1': 'No, we do not provide cleaning supplies. Customer needs to provide.',
  'faq-a2': 'Yes, if you are not satisfied with us, we refund back your fee.',
  'faq-a3': 'Yes, if the task is completed within the requested time.',
  'faq-a4': '2 hours',
  'faq-a5': 'Yes.',
  'faq-a6': 'No. Schedule is fixed, not changeable with any reason.',
  'faq-a7': 'Yes, this is a must.',
  'faq-a8': 'Yes, but you need to instruct what you need first.',
  'faq-a9': 'Call 02-714-2116 / 080-629-6734. Our customer support immediately follow up your case.',
  'faq-a10': 'Yes',
  'faq-a11': 'No, if we find this kind of case behind the company, we will charge the penalty fee.',
  'faq-foot': 'We’d like to help and talk with you',
  'alert-schedule-after-hr': 'Please request at least %d hours before schedule',
  'alert-schedule-after-day': 'Please request at least %d day(s) before schedule',
  'invite': 'Invite friends',
  'invite-fb': 'facebook',
  'invite-twitter': 'twitter',
  'invite-email': 'email',
  'share-your-link': 'share your referral link',
  'share-discount': 'Your friends get a 200฿ discount on their first service and you get a 200฿ discount for every friend who does.',
  'share-title': 'Invite your friends!',
  'startup-press': 'On the press',
  'redeem': 'Redeem promo code',
  'description-login': 'Login to Ayasan',
  'description-register': 'Register Ayasan',
  'supporter-info-poor': 'Poor',
  'supporter-info-fair': 'Fair',
  'supporter-info-good': 'Good',
  'supporter-info-th': 'Speak Thai',
  'supporter-info-en': 'Speak English',
  'supporter-info-child': 'Child care (age)',
  'switch-language': 'Switch language',
  'elder-description': 'Please provide description of an elder',
  'submenu-more': 'More',
  'no-cleaning-supply': '*We do not provide cleaning supplies.',
  'requested_helper_name': 'Helper\'s name',
  'requested_helper': 'Request helper (+100฿)',
  'requested_helper_2': 'Requested helper:',
  'see-all-services': 'See all services',
  'all-services': 'All services',
  'new': 'New',
  'month': 'Month(s)',
  'recommend': 'Recommend',
  'include-transportation': 'Included transportation cost',
  'ondemand-service': 'On demand service',
  'maid-subscription-service': 'Maid service package',
  'request-when-needed': 'Request when needed',
  'book-subscription': 'Book a service',
  'new-service': 'New service!',
  'new-service-description': 'Try out our maid service package, today.',
  'name': 'Your name',
  'package': 'Package',
  'send-email': 'Send email',
  'subscription-success': 'Thank you for your interest.\n We\'ve received your request. We will reply you back soon.',
  'reset-password': 'Reset password',
  'forget-password-detail': 'Please specify your email that you\'ve register.',
  'forget-password-success': 'Email sent',
  'forget-password-inbox': 'Please check you inbox to confirm and reset your password.',
  'payment-method': 'Payment method',
  'line-pay': 'LINE pay',
  'usage': 'Usage',
  'remaining': 'Remaining',
  'confirm': 'Confirm',
  'use-package': 'Use package',
  'package-remaining': 'Your package has {hour} hours left',
  'need-package': 'Need a monthly package?',
  'need-more-package': 'Need more package?',
  'buy-package': 'Buy package',
  'use-other': 'Not this time',
  'add-payment': 'Add credit card',
  'add-card': 'Add card',
  'cancel': 'Cancel',
  'valid-thru': 'Valid thru',
  'your-name': 'Your name',
  'card-number': 'Card number',
  'credit-card-error': 'We were unable to authorize the payment to your credit card. Please confirm that all the details are correct. You may also contact your bank to confirm that they are not blocking the payment or use an alternate payment method.',
  'card-expiry': 'Expires {month}/{year}',
  'no-payment': 'You don\'t have any payment method',
  'wrong-card-number': 'Please input a correct card number',
  'wrong-valid-thru': 'Please input a correct valid thru',
  'wrong-cvc': 'Please input a correct cvc',
  'required-name': 'Please input your name',
  'credit-card': 'Credit card',
  'add_payment': 'Missing credit card',
  'setup_card_description': 'Please go to select credit card as payment',
  'yes': 'Yes',
  'no': 'No',
  'go-back': 'Go back',
  'package_notify_not_enough': 'You only have {hours} hours of package left. Please buy more package or request using other payment method',
  'subscription-usage-history': 'View usage history',
  'subscription-payment-history': 'View payment history',
  'pay': 'Pay',
  'status-payment-paid': 'Paid',
  'status-payment-pending': 'Pending',
  'status-payment-canceled': 'Canceled',
  'package-amount': 'Amount',
  'click-setup-card': 'Click to setup credit card',
  'cancel-request': 'Cancel request',
  'cancel-request-confirmation': 'You are sure to cancel the request?',
  'firstname-error': 'Please input your Firstname',
  'lastname-error': 'Please input your Lastname',
  'email-error': 'Please input your Email',
  'password-error': 'Please input your Password',
  'support-creditcard': 'Available Payment Method',
}
