import { createBrowserHistory } from 'history'

class History {

  currentHistory = createBrowserHistory()

  create(basename = '/') {
    this.currentHistory = createBrowserHistory({ basename })    
    return this.currentHistory
  }
}

export default new History()
