import history from '../history'
import * as Constants from './constants'

function a(fn, url) {
  // console.log(history.currentHistory);
  // const { language: { locale, allLocales }, routing: { locationBeforeTransitions } } = getState()
  // const nextPath = (() => {
  //   const lang = allLocales.find(locale => locationBeforeTransitions && locationBeforeTransitions.pathname && locationBeforeTransitions.pathname.includes(locale))
  //   if (lang) {
  //     return `/${locale}${url}`
  //   } else {
  //     return url
  //   }
  // })()
  fn(url)
}

export const goBack = history.currentHistory.goBack
export const goToIndex = () => window.location = 'https://www.ayasan-app.com/'
export const goToApp = () => a(history.currentHistory.push, '/')
export const goToLogin = () => a(history.currentHistory.push, '/login')
export const goToPayment = () => a(history.currentHistory.push, '/payment')
export const goToSubscription = () => a(history.currentHistory.push, '/subscription')
export const replaceToApp = () => a(history.currentHistory.replace, '/')
export const replaceToLogin = () => a(history.currentHistory.replace, '/login')
export const replaceToRegister = () => a(history.currentHistory.replace, '/register')
export const replaceToInProgress = () => a(history.currentHistory.replace, '/history/inprogress')
export const replaceToSubscriptionPayment = () => a(history.currentHistory.replace, '/subscription/history')

export const setRedirectUrl = redirectTo => ({
  type: Constants.SET_REDIRECT_URL,
  redirectTo,
})

export const clearRedirectUrl = () => ({
  type: Constants.CLEAR_REDIRECT_URL,
})

export const locationChange = location => ({
  type: Constants.LOCATION_CHANGE,
  location,
})

export const redirectTo = redirectTo => a(history.replace, redirectTo)
export const pushTo = pushTo => a(history.currentHistory.push, pushTo)
