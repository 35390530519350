import { combineReducers } from 'redux'
import language from './language'
import experiment from './experiment'
import session from './session'
import jobs from './jobs'
import reviews from './reviews'
import price from './price'
import promotion from './promotion'
import redirect from './redirect'
import subscriptions from './subscriptions'
import pages from './pages'
import creditCards from './creditCards'
import packageList from './packageList'
import views from './views'

export default combineReducers({
  pages,
  experiment,
  redirect,
  promotion,
  language,
  session,
  jobs,
  reviews,
  price,
  subscriptions,
  creditCards,
  packageList,
  views,
})
