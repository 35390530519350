import Parse from 'parse'
import { clearReferral } from './promotion'
import * as ParseApi from '../apis/parse'
import * as Constants from './constants'

function attemptLogin(){
  return {
    type: Constants.REQUEST_LOGIN
  }
}

function didLogout(){
  return {
    type: Constants.IS_LOGOUT
  }
}

function fail(err){
  return {
    type: Constants.REQUEST_LOGIN_FAIL,
    error: err
  }
}

function setConfig(config){
  return {
    type: Constants.SET_CONFIG,
    config
  }
}

function isLogin(user) {
  return {
    type: Constants.IS_LOGIN,
    user
  }
}

export function setUser(user){
  return (dispatch) => {
    dispatch(isLogin(user))
  }
}

export function getConfig() {
  return dispatch => {
    Parse.Config.get().then(config => {
      dispatch(setConfig(config))
    })
  }
}

export function incrementActiveJob() {
  return {
    type: Constants.INCREMENT_ACTIVE_JOB,
  }
}

export function receiveActiveJob(count) {
  return {
    type: Constants.RECEIVE_ACTIVE_JOB,
    activeJob: count,
  }
}

export function resetPassword(email) {
  return {
    type: Constants.RESET_PASSWORD_POST,
    email,
  }
}

export function saveUserInfo(email, firstname, lastname, phone, imageFile) {
  return dispatch => {

    return ParseApi.saveUserInfo(email, firstname, lastname, phone, imageFile).then(user => {
      dispatch(setUser(user))
      return Promise.resolve(user)
    }).catch((err) => {
      dispatch(fail(err))
      return Promise.reject(err)
    })
  }
}

export function savePayment(payment) {
  return dispatch => {

    return ParseApi.savePayment(payment).then(user => {
      dispatch(setUser(user))
      return Promise.resolve(user)
    }).catch((err) => {
      dispatch(fail(err))
      return Promise.reject(err)
    })
  }
}

export function savePhone(phone) {
  return dispatch => {

    return ParseApi.savePhone(phone).then(user => {
      dispatch(setUser(user))
      return Promise.resolve(user)
    }).catch((err) => {
      dispatch(fail(err))
      return Promise.reject(err)
    })
  }
}

export function saveAddress(address) {
  return dispatch => {

    return ParseApi.saveAddress(address).then(user => {
      dispatch(setUser(user))
      return Promise.resolve(user)
    }).catch((err) => {
      dispatch(fail(err))
      return Promise.reject(err)
    })
  }
}

export function getActiveJob() {
  return (dispatch) => {
    ParseApi.getCustomerActiveJobCount().then(count => {
      return dispatch(receiveActiveJob(count))
    }).then(res => {
      //dispatch(setUser(user))
    })
  }
}

export function loginFacebook() {
  return dispatch => {

    dispatch(attemptLogin())
    return ParseApi.loginCustomerFacebook().then(user => {
      dispatch(setUser(user))
      return Promise.resolve(user)
    }).catch((err) => {
      dispatch(fail(err))
      return Promise.reject(err)
    })
  }
}

export function login(email, password) {
  return dispatch => {
    dispatch(attemptLogin())
    return ParseApi.loginCustomer(email, password).then(user => {
      dispatch(setUser(user))
      return Promise.resolve(user)
    }).catch((err) => {
      dispatch(fail(err))
      return Promise.reject(err)
    })
  }
}

export function register(email, password, firstname, lastname, phone) {
  return (dispatch, getState) => {

    const { promotion } = getState()
    dispatch(attemptLogin())

    return ParseApi.registerUser(email, password, firstname, lastname, phone).then(user => {
      if (promotion.referralCode) {
        return ParseApi.useReferralCode(promotion.referralCode).then(res => {
          dispatch(clearReferral())
          return user
        }).catch(err => {
          dispatch(clearReferral())
          return user
        })
      }
      return user
    }).then(user => {
      dispatch(setUser(user))
      return Promise.resolve(user)
    }).catch((err) => {
      dispatch(fail(err))
      return Promise.reject(err)
    })
  }
}

export function logout() {
  return dispatch => {
    return ParseApi.logout().then(()=> {
      dispatch(didLogout())
      return Promise.resolve()
    })
  }
}
