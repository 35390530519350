import { th, en, allLocales } from '../locales'
import { INIT_LANGUAGE } from '../actions/constants'

const initialState = {
  allLocales,
  locale: null,
  translation: null,
}

function mapLanguage(language) {
  switch (language) {
    case 'th-TH':
      return th
    case 'en-EN':
      return en
    default:
      return th
  }
}

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case INIT_LANGUAGE:
      return {
        ...state,
        locale: action.locale,
        translation: mapLanguage(action.locale),
      }
    default:
      return state
  }
}
