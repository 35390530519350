import { SET_REDIRECT_URL, CLEAR_REDIRECT_URL } from '../actions/constants'

export default function(state = {
  location: ''
}, action = {}) {
  switch (action.type) {
    case SET_REDIRECT_URL:
      return {
        ...state,
        location: action.location,
      }
    case CLEAR_REDIRECT_URL:
      return {
        ...state,
        location: '',
      }
    default:
      return state
  }
}
