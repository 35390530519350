import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import { render } from 'react-dom'
import { LocaleProvider } from 'antd'
import { Provider } from 'react-redux'
import { setUser } from './actions/session'
import { initLanguage } from './actions/language'
import { initReferral } from './actions/promotion'
import { initializeParse, initFacebook, fromSession } from './apis/parse'
import { getUrlParams } from './utils/location'
import * as serviceWorker from './serviceWorker'
import ga from 'react-ga'
import createStore from './stores/createStore'
import AppRoot from './AppRoot'
import enUS from 'antd/lib/locale-provider/en_US'
import 'react-credit-cards/lib/styles-compiled.css'
import * as Sentry from '@sentry/browser'

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN })
ga.initialize(process.env.REACT_APP_GA_ID)

const query = getUrlParams(document.location.href)

const store = createStore()
store.dispatch(initLanguage())
if (query.referralCode) store.dispatch(initReferral(query.referralCode))

const doRender = () => {
  render(
    <Provider store={store}>
      <LocaleProvider locale={enUS}>
        <AppRoot />
      </LocaleProvider>
    </Provider>
    ,
    document.getElementById('root')
  )
}

initializeParse().then(user => {
  if (query.sessionToken) return fromSession(query.sessionToken)
  if (user) return fromSession(user.sessionToken)
  return Promise.reject()
}).then(user => {
  store.dispatch(setUser(user))
}).then(doRender).catch(doRender)

//Hotjar
if (!query.sessionToken) {
  ((h,o,t,j,a,r) => {
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:780392,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}

//Facebook pixel
(function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)})(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
window.fbq('init', '1978926675478980');
window.fbq('track', 'PageView');

//Facebook sdk
((d, s, id) => {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = "//connect.facebook.net/en_US/all.js";
  fjs.parentNode.insertBefore(js, fjs);
})(document, 'script', 'facebook-jssdk');

window.fbAsyncInit = () => {
  initFacebook()
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
