import { combineReducers } from 'redux'
import * as Constants from '../actions/constants'

export default combineReducers({
  allIds,
  byId,
})

function allIds(state = [], action = {}) {
  switch (action.type) {
    case Constants.CREDIT_CARD_POST_SUCCESS:
      if (!state.includes(action.payload.objectId)) {
        return [...state, action.payload.objectId]
      }
      return state
    case Constants.CREDIT_CARDS_GET_SUCCESS:
      const array = [...state]
      action.payload.forEach((payload) => {
        if (!array.includes(payload.objectId)) {
          array.push(payload.objectId)
        }
      })
      return array
    default:
      return state
  }
}

function byId(state = {}, action = {}) {
  switch (action.type) {
    case Constants.CREDIT_CARD_POST_SUCCESS:
      return Object.assign({}, state, {
        [action.payload.objectId]: action.payload,
      })
    case Constants.CREDIT_CARDS_GET_SUCCESS:
      return Object.assign({}, state,
        action.payload.reduce((obj, item) => {
          obj[item.objectId] = item
          return obj
        }, {}),
      )
    default:
      return state
  }
}
