import { call, put, takeLatest } from 'redux-saga/effects'
import * as Constants from '../actions/constants'
import * as ParseApi from '../apis/parse'
import { hideCreditCardForm } from '../actions/views/paymentMethod'
import { createToken } from '../apis/omise'

export function* getCreditCardsSaga() {
  try {
    const payload = yield call(ParseApi.getCreditCards)
    yield put({ type: Constants.CREDIT_CARDS_GET_SUCCESS, payload })
  } catch (err) {
    yield put({ type: Constants.CREDIT_CARDS_GET_FAILED, err })
  }
}

export function* watchGetCreditCards() {
  yield takeLatest(Constants.CREDIT_CARDS_GET, getCreditCardsSaga)
}

export function* postCreditCard(action) {
  try {
    const token = yield call(createToken, action.name, action.number, action.expirationMonth, action.expirationYear, action.cvc)
    if (!token.card.security_code_check) {
      yield Promise.reject('Invalid CCV')
    }
    const payload = yield call(ParseApi.postCreditCard, token.id)
    yield put(hideCreditCardForm())
    yield put({ type: Constants.CREDIT_CARD_POST_SUCCESS, payload })
  } catch (err) {
    yield put({ type: Constants.CREDIT_CARD_POST_FAILED, err })
  }
}

export function* watchPostCreditCard() {
  yield takeLatest(Constants.CREDIT_CARD_POST, postCreditCard)
}

export function* selectCreditCard(action) {
  try {
    const payload = yield call(ParseApi.selectCreditCard, action.creditCardId)
    yield put({ type: Constants.CREDIT_CARD_SELECT_SUCCESS, payload, creditCardId: action.creditCardId })
  } catch (err) {
    yield put({ type: Constants.CREDIT_CARD_SELECT_FAILED, err })
  }
}

export function* watchSelectCreditCard() {
  yield takeLatest(Constants.CREDIT_CARD_SELECT, selectCreditCard)
}

export function* getCreditCard(action) {
  try {
    const payload = yield call(ParseApi.getCreditCard, action.id)
    yield put({ type: Constants.CREDIT_CARD_GET_SUCCESS, payload })
  } catch (err) {
    yield put({ type: Constants.CREDIT_CARD_GET_FAILED, err })
  }
}

export function* watchGetCreditCard() {
  yield takeLatest(Constants.CREDIT_CARD_GET, getCreditCard)
}
