import { combineReducers } from 'redux'
import { RECEIVE_JOB, RECEIVE_JOBS } from '../actions/constants'

export default combineReducers({
  allIds,
  byId,
})

function allIds(state = [], action = {}) {
  switch (action.type) {
    case RECEIVE_JOB:
      if (!state.includes(action.job.id)) {
        return [...state, action.job.id]
      }
      return state
    case RECEIVE_JOBS:
      const array = [...state]
      action.jobs.forEach(job => {
        if (!array.includes(job.id)) {
          array.push(job.id)
        }
      })
      return array
    default:
      return state
  }
}

function byId(state = {}, action = {}) {
  switch (action.type) {
    case RECEIVE_JOB:
      return Object.assign({}, state, {
        [action.job.id]: action.job
      })
    case RECEIVE_JOBS:
      return Object.assign({}, state,
        action.jobs.reduce((obj, job) => {
          obj[job.id] = job
          return obj
        }, {})
      )
    default:
      return state
  }
}
