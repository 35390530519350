import { combineReducers } from 'redux'
import * as Constants from '../actions/constants'

export default combineReducers({
  allIds,
  byId,
})

function allIds(state = [], action = {}) {
  switch (action.type) {
    // case RECEIVE_SUBSCRIPTION:
    //   if (!state.includes(action.payload.id)) {
    //     return [...state, action.payload.id]
    //   }
    //   return state
    case Constants.PACKAGE_LIST_GET_SUCCESS:
      const array = [...state]
      action.payload.forEach(subscription => {
        if (!array.includes(subscription.objectId)) {
          array.push(subscription.objectId)
        }
      })
      return array
    default:
      return state
  }
}

function byId(state = {}, action = {}) {
  switch (action.type) {
    // case RECEIVE_SUBSCRIPTION:
    //   return Object.assign({}, state, {
    //     [action.payload.id]: action.payload
    //   })
    case Constants.PACKAGE_LIST_GET_SUCCESS:
      return Object.assign({}, state,
        action.payload.reduce((obj, subscription) => {
          obj[subscription.objectId] = subscription
          return obj
        }, {})
      )
    default:
      return state
  }
}
