
export const RESET_PASSWORD_POST = 'RESET_PASSWORD_POST'
export const RESET_PASSWORD_POST_SUCCESS = 'RESET_PASSWORD_POST_SUCCESS'
export const RESET_PASSWORD_POST_FAILED = 'RESET_PASSWORD_POST_FAILED'

export const ACTIVATE_EXPERIMENT = 'ACTIVATE_EXPERIMENT'
export const DEACTIVATE_EXPERIMENT = 'DEACTIVATE_EXPERIMENT'

export const LOGIN_FACEBOOK = 'LOGIN_FACEBOOK'
export const LOGIN_FACEBOOK_SUCCESS = 'LOGIN_FACEBOOK_SUCCESS'
export const LOGIN_FACEBOOK_FAILED = 'LOGIN_FACEBOOK_FAILED'

export const SIGNUP_EMAIL = 'SIGNUP_EMAIL'
export const SIGNUP_EMAIL_SUCCESS = 'SIGNUP_EMAIL_SUCCESS'
export const SIGNUP_EMAIL_FAILED = 'SIGNUP_EMAIL_FAILED'

export const LOGIN_EMAIL = 'LOGIN_EMAIL'
export const LOGIN_EMAIL_SUCCESS = 'LOGIN_EMAIL_SUCCESS'
export const LOGIN_EMAIL_FAILED = 'LOGIN_EMAIL_FAILED'

export const LOGOUT = 'LOGOUT'

export const IS_LOGIN = 'IS_LOGIN'
export const IS_LOGOUT = 'IS_LOGOUT'
export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const REQUEST_LOGIN_FAIL = 'REQUEST_LOGIN_FAIL'
export const SET_CONFIG = 'SET_CONFIG'
export const RECEIVE_ACTIVE_JOB = 'RECEIVE_ACTIVE_JOB'
export const INCREMENT_ACTIVE_JOB = 'INCREMENT_ACTIVE_JOB'

export const SET_REDIRECT_URL = 'SET_REDIRECT_URL'
export const CLEAR_REDIRECT_URL = 'CLEAR_REDIRECT_URL'

export const INIT_REFERRAL = 'INIT_REFERRAL'
export const CLEAR_REFERRAL = 'CLEAR_REFERRAL'

export const PROMOTION_GET_SUCCESS = 'PROMOTION_GET_SUCCESS'
export const PROMOTION_APPLY_SUCCESS = 'PROMOTION_APPLY_SUCCESS'
export const INIT_PROMO_CODE = 'INIT_PROMO_CODE'
export const CLEAR_PROMO_CODE = 'CLEAR_PROMO_CODE'

export const RECEIVE_REVIEWS = 'RECEIVE_REVIEWS'
export const RECEIVE_REVIEW = 'RECEIVE_REVIEW'

export const INIT_SEO = 'INIT_SEO'

export const GET_PRICE_SUCCESS = 'GET_PRICE_SUCCESS'

export const RECEIVE_JOB = 'RECEIVE_JOB'
export const RECEIVE_JOBS = 'RECEIVE_JOBS'

export const RECEIVE_SUBSCRIPTION = 'RECEIVE_SUBSCRIPTION'
export const RECEIVE_SUBSCRIPTIONS = 'RECEIVE_SUBSCRIPTIONS'

export const CREDIT_CARDS_GET = 'CREDIT_CARDS_GET'
export const CREDIT_CARDS_GET_SUCCESS = 'CREDIT_CARDS_GET_SUCCESS'
export const CREDIT_CARDS_GET_FAILED = 'CREDIT_CARDS_GET_FAILED'

export const CREDIT_CARD_GET = 'CREDIT_CARD_GET'
export const CREDIT_CARD_GET_SUCCESS = 'CREDIT_CARD_GET_SUCCESS'
export const CREDIT_CARD_GET_FAILED = 'CREDIT_CARD_GET_FAILED'

export const CREDIT_CARD_POST = 'CREDIT_CARD_POST'
export const CREDIT_CARD_POST_SUCCESS = 'CREDIT_CARD_POST_SUCCESS'
export const CREDIT_CARD_POST_FAILED = 'CREDIT_CARD_POST_FAILED'

export const CREDIT_CARD_SELECT = 'CREDIT_CARD_SELECT'
export const CREDIT_CARD_SELECT_SUCCESS = 'CREDIT_CARD_SELECT_SUCCESS'
export const CREDIT_CARD_SELECT_FAILED = 'CREDIT_CARD_SELECT_FAILED'

export const SUBSCRIPTION_REQUEST_CREATE = 'SUBSCRIPTION_REQUEST_CREATE'
export const SUBSCRIPTION_REQUEST_CREATE_SUCCESS = 'SUBSCRIPTION_REQUEST_CREATE_SUCCESS'
export const SUBSCRIPTION_REQUEST_CREATE_FAILED = 'SUBSCRIPTION_REQUEST_CREATE_FAILED'

export const PACKAGE_LIST_GET = 'PACKAGE_LIST_GET'
export const PACKAGE_LIST_GET_SUCCESS = 'PACKAGE_LIST_GET_SUCCESS'
export const PACKAGE_LIST_GET_FAILED = 'PACKAGE_LIST_GET_FAILED'

export const SHOW_CREDIT_CARD_FORM = 'SHOW_CREDIT_CARD_FORM'
export const HIDE_CREDIT_CARD_FORM = 'HIDE_CREDIT_CARD_FORM'

export const LOCATION_CHANGE = 'LOCATION_CHANGE'

export const INIT_LANGUAGE = 'INIT_LANGUAGE'
