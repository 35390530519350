import { combineReducers } from 'redux'
import { GET_PRICE_SUCCESS } from '../actions/constants'

export function stringifyPrice(action) {
  const ordered = {};
  Object.keys(action.query).sort().forEach(key => {
    ordered[key] = action.query[key];
  })
  return JSON.stringify({
    service: action.service,
    query: ordered,
  })
}

export default combineReducers({
  allQueries,
  byQuery,
})

function allQueries(state = [], action = {}) {
  switch (action.type) {
    case GET_PRICE_SUCCESS:
      const array = [...state]
      if (array.indexOf(stringifyPrice(action)) < 0) {
        array.push(stringifyPrice(action))
      }
      return array
    default:
      return state
  }
}

function byQuery(state = {}, action = {}) {
  switch (action.type) {
    case GET_PRICE_SUCCESS:
      return Object.assign({}, state, {
        [stringifyPrice(action)]: action.price
      })
    default:
      return state
  }
}
