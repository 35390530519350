export default {
  "english": "Eng",
  "thai": "ไทย",
  'language': 'ภาษา',
  "seo-title": "Ayasan | บริการทำความสะอาดและเลี้ยงเด็กในคลิ้กเดียว กทม. ประเทศไทย - รายวัน / รายเดือน",
  "seo-description": "บริการ ทำความสะอาด/เลี้ยงเด็ก/ดูแลผู้สูงอายุ ในคลิ้กเดียว. เริ่มต้น 150 บาท / ชม. และบริการอื่นๆ ช่างซ่อม/ล้างแอร์ กทม. ประเทศไทย",
  "seo-keywords": "บริการทำความสะอาด กรุงเทพ, บริการแม่บ้าน กรุงเทพ, บริการพี่เลี้ยงเด็ก กรุงเทพ, บริการดูแลบ้าน กรุงเทพ",
  "title":"“เรียกแม่บ้านง่ายๆ แค่คลิ้กเดียว”",
  "subtitle":"เรียกผู้ช่วยเหลือได้ง่ายๆ แค่ปลายนิ้ว",
  "already-member":"เป็นสมาชิกแล้ว?",
  "how-work":"Ayasan ทำงานอย่างไร?",
  "send-request":"ส่งคำขอ",
  "signup":"สมัครสมาชิก",
  "send-request-desc":"ระบุความละเอียดงานให้เหมาะสมกับความต้องการของคุณ เรียกตอนนี้ได้เลย",
  "choose-helper":"เลือกผู้ช่วย",
  "choose-helper-desc":"พนักงานมืออาชีพของเราจะเดินทางไปหาคุณ",
  "life-easy":"ทำชีวิตให้ง่าย",
  "life-easy-desc":"งานบ้านของคุณเสร็จและการันตีความพึงพอใจ",
  "just-find-your":"เพียงแค่หา",
  "just-find-your-2":"ผู้ช่วยรอบๆตัวคุณ",
  "promote-users": "ผู้ใช้งานมากกว่า 4,000 คน พึงพอใจในบริการของเรา!",
  "why-our":"ทำไมต้อง",
  "why-our-2":"บริการของเรา",
  "maid":"แม่บ้าน",
  "nanny":"พี่เลี้ยง",
  "elder":"ดูแลผู้สูงอายุ",
  "dogsitter":"ดูแลสุนัข",
  "messenger":"ผู้ส่งสาร",
  "tutor":"สอนภาษาอังกฤษ",
  "repairer":"ช่างซ่อม",
  "time":"เวลา",
  "time-desc":"ประหยัดเวลาของคุณ",
  "support":"ช่วยเหลือ",
  "support-desc":"บริการช่วยเหลือลูกค้า 24 ชม.",
  "quality":"คุณภาพ",
  "quality-desc":"ทุกคนได้ผ่านการตรวจสอบแล้ว",
  "payment":"ชำระเงินง่าย",
  "payment-desc":"ใช้แค่บัตร ไม่ต้องใช้เงินสด",
  "tech":"เทคโนโลยี",
  "tech-desc":"ระบบของเราใช้เทคโนโลยีใหม่ล่าสุด",
  "ayasan-job-desc":"ต้องการหารายได้กับ Ayasan?",
  "ayasan-job-signup":"สมัครที่นี่",
  "signin":"เข้าสู่ระบบ",
  "username":"อีเมลล์",
  "password":"รหัสผ่าน",
  "remember":"จดจำ",
  "forget-password":"ลืมรหัสผ่าน?",
  "click-here":"กดที่นี่",
  "thank-you":"ขอบคุณที่สนใจบริการ Ayasan",
  "download":"คุณสามารถโหลดแอพได้ที่นี่",
  "download-easy":"ดาวน์โหลดแอพเพื่อเรียกใช้บริการได้ง่ายขึ้น",
  //"our-company":"บริษัท ",
  "our-company-2":"ของเรา",
  "learn-more":"ดูเพิ่มเติม",
  "download-now":"โหลดแอพเลย !",
  "review-section-title":"ลูกค้าของเราชอบบริการของเรา",
  "ac":"ล้างแอร์",
  logout: 'ออกจากระบบ',
  "login-facebook": 'เข้าสู่ระบบด้วย Facebook',
  "register-facebook": 'เชื่อมต่อด้วย Facebook',
  "register-email": 'สมัครด้วยอีเมลล์',
  "request-service": 'เรียกใช้',
  "request-service-loading": 'กำลังส่งคำขอ...',
  hour: 'ชม.',
  unit: 'เครื่อง',
  units: 'เครื่อง',
  "menu-request-service": 'บริการ',
  "setting-payment": 'การชำระเงิน',
  "edit-profile": 'แก้ไขข้อมูลส่วนตัว',
  "terms-of-use": 'ข้อตกลงการใช้งาน',
  "privacy-policy": 'นโยบายความเป็นส่วนตัว',
  "job-list": 'งานของฉัน',
  address: 'ที่อยู่',
  "schedule-at": 'กำหนดการ',
  "add-address": 'ระบุที่อยู่',
  "add-phone": 'ระบุเบอร์โทร',
  floors: 'จำนวนชั้น',
  rooms: 'จำนวนห้อง',
  "working-hours": 'จำนวนชั่วโมง',
  hours: 'ชั่วโมง',
  cleaning: 'ทำความสะอาด',
  "speak-english": 'พูดอังกฤษ',
  iron: 'รีดผ้า',
  laundry: 'ซักผ้า',
  cash: 'เงินสด',
  promo: 'โปรโมชั่น',
  estimate: 'ราคา:',
  save: 'บันทึก',
  saving: 'กำลังบันทึก',
  'update-address': 'กรุณาอัพเดทที่อยู่ของคุณ',
  'your-address': 'ถนน, เขต และจังหวัด',
  'address-number': 'เลขที่บ้าน',
  'room-number': 'เลขที่ห้อง',
  'room-number-error': 'กรุณาใส่เลขที่ห้อง',
  'address-number-error': 'กรุณาใส่เลขที่บ้าน',
  'condo': 'คอนโด',
  'apartment': 'อพาร์ทเม้น',
  'house': 'บ้าน',
  'address-error': 'กรุณาใส่ที่อยู่ให้ถูกต้อง',
  'phone-error': 'กรุณาใส่เบอร์โทรให้ถูกต้อง',
  'date-error': 'กรุณาระบุวันที่ให้ถูกต้อง',
  'child-age': 'อายุเด็ก',
  'dog-size': 'ขนาดสุนัข',
  'promo-error': 'กรุณาใส่รหัส',
  'apply-promo-success': 'ใช้รหัสโปรโมชั่นเรียบร้อย',
  'apply': 'ตกลง',
  "enter-promo": 'ใส่รหัสโปรโมชั่น',
  'ac-no-title': 'จำนวนเครื่อง',
  'ac-type-title': 'ชนิดของแอร์',
  'ac-btu': 'บีทียู',
  'ac-btu-over': 'สูงกว่า',
  'ac-btu-not-over': 'ต่ำกว่า',
  'ac-not-specify': 'ไม่ระบุ',
  'repairer-desc-title': 'โปรดใส่รายละเอียดของที่ต้องการให้ซ่อม',
  'description': 'รายละเอียด',
  'ac-wall': 'ติดผนัง',
  'ac-floor': 'ตั้งพื้น',
  'ac-ceiling': 'ติดเพดาน',
  and: 'และ',
  'signup-terms': 'คุณยอมรับเงื่อนไข',
  'first-name': 'ชื่อจริง',
  'last-name': 'นามสกุล',
  'phone-number': 'เบอร์โทรติดต่อ',
  'email': 'อีเมลล์',
  'create-account': 'กดเพื่อสร้างบัญชีผู้ใช้',
  'sign-up-free': 'สมัครสมาชิกฟรี',
  'back-to': 'กลับสู่',
  'dont-have-account': 'ยังไม่ได้เป็นสมาชิก?',
  'write-comment': 'ใส่ความคิดเห็น...',
  'rate-satisfaction': 'กรุณาใส่คะแนนความพึงพอใจ',
  'tap-change-image': 'กดเพื่อเปลี่ยนรูป',
  'no-inprogress': 'ไม่มีงานที่กำลังดำเนินการ',
  'no-inprogress-request': 'ผู้ช่วยของเราพร้อมที่จะช่วยคุณ เรียกใช้บริการเลย',
  'status-waiting': 'กำลังรอ',
  'status-cancel': 'ยกเลิก',
  'status-match': 'รับงานแล้ว',
  'status-done': 'เสร็จสิ้น',
  'customers': 'ผู้ใช้งาน',
  'rating': 'คะแนน',
  'reviews': 'รีวิว',
  price: 'ราคา',
  'job-detail': 'รายละเอียดงาน',
  'confirm-modal-title': 'คุณกำลังเรียกใช้บริการ Ayasan',
  'confirm-modal-context': 'เราจะติดต่อกลับไปในไม่ช้า กดปุ่มยืนยันเพื่อดำเนินการต่อ',
  'confirm-modal-ok': 'ยืนยันการเรียกใช้',
  inprogress: 'งานปัจจุบัน',
  completed: 'เสร็จสิ้นแล้ว',
  'no-completed': 'ไม่มีประวัติการใช้งาน',
  'wait-supporter': 'กำลังรอผู้ช่วยเหลือ',
  'price-hour': 'ราคาต่อชั่วโมง',
  'price-unit': 'ราคาต่อเครื่อง',
  'total-hour': 'จำนวนชั่วโมง',
  'total-unit': 'จำนวนเครื่อง',
  total: 'รวม',
  charged: 'เรียกเก็บเงินแล้ว',
  'signup-title': 'สมัครสมาชิกเพื่อเรียกใช้บริการ',
  'signin-title': 'เข้าสู่ระบบเพื่อเรียกใช้บริการ',
  'intro-request-service': 'เรียกใช้บริการ',
  'login': 'เข้าสู่ระบบ',
  'request-from-web': 'เรียกใช้บริการผ่านเวป',
  or: 'หรือ',
  'maid-service': 'ใช้บริการแม่บ้าน',
  'review-section-1': 'มุ่งมั่นสร้างความประทับใจ',
  'review-section-2': 'เรายังคงพัฒนาคุณภาพในงานให้บริการอย่างต่อเนื่อง และ เรายินดีรับคำติชม',
  'intro-1': 'บริการแม่บ้านอย่างมืออาชีพ',
  'intro-2': 'ด้วยประสบการณ์ที่ยาวนานของเรา รับรองได้ว่าพนักงานของเราทุกคนผ่านมาตรฐานการอบรมมาเป็นอย่างดี',
  'intro-2-safe': 'การันตีความปลอดภัย 100%',
  'intro-3': 'หากไม่ประทับใจในบริการของเรา\nเรายินดีคืนเงินลูกค้า 100%',
  'intro-button': 'ดูบริการทั้งหมด',
  'our-service': 'บริการของเรา',
  'our-service-2': 'คุณภาพของงานบริการ คือ หน้าที่ของเรา',
  'refund': 'คืนเงิน 100%',
  'select-service': 'เลือกใช้บริการ',
  'how-to-1': 'เรียกใช้บริการง่ายๆ เพียงไม่กี่ขั้นตอน',
  'how-to-2': 'เลือกบริการที่ต้องการให้เราดูแล ไม่ว่าจะเป็น แม่บ้าน พี่เลี้ยง ล้างแอร์ หรือ ช่างซ่อม',
  'how-to-3': 'กรอกข้อมูล',
  'how-to-4': 'เพียงแค่กรอกรายละเอียดในเรื่องที่คุณต้องการให้เราดูแล',
  'how-to-5': 'ส่งถึงเรา',
  'how-to-6': 'กรอกข้อมูลครบแล้ว ก็ส่งถึงเราได้เลย จากนั้นเราจะติดต่อคุณเพื่อยืนยันการจองให้เร็วที่สุด',
  'mobile-app-1': 'สามารถเรียกใช้บริการผ่านแอปมือถือได้แล้ววันนี้ การเรียกใช้บริการจะไม่ยากอีกต่อไป ทั้ง สะดวก รวดเร็ว และ ประหยัดเวลา',
  'more-1': 'ยังคงมองหาผู้ช่วย?',
  'more-2': 'หากยังไม่แน่ใจว่าผู้ให้บริการผู้ช่วยเจ้าไหนดี',
  'more-3': 'ขออาสาบริการคุณ ด้วยประสบการณ์ที่ยาวนาของเรา รับรองได้ว่าพนักงานของเราได้ผ่านการอบรมมาตรฐานมาเป็นอย่างดี ในงานให้บริการ',
  'our-company': 'ผู้ให้บริการในเครือ',
  'startup-community': 'เราเข้าร่วมแสดงผลงานในงาน Start Up',
  'contact-us': 'ติดต่อเจ้าหน้าที่',
  'contact-us-fb': 'ติดตามข่าวสารทาง Facebook',
  'contact-us-line': 'สอบถามบริการทาง Line',
  'maid-detail-1': 'ให้บริการทำความสะอาดบ้าน\nคอนโด / ห้องพัก รวมไปถึงซักรีด',
  'nanny-detail-1': 'ให้บริการดูแลเด็กใกล้ชิด\nให้อาหาร อาบน้ำ',
  'ac-detail-1': 'ให้บริการล้างแอร์ ขนาด\nเล็ก / ใหญ่',
  'repairer-detail-1': 'ให้บริการซ่อมของชำรุดภายในบ้าน\nไฟฟ้า / ประปา',
  'get-inquiry': 'ขอใบเสนอราคาฟรี',
  faq: 'FAQ',
  'faq-full': 'คำถามที่พบบ่อย',
  'faq-q1': 'บริษัทเตรียมอุปกรณ์ทำความสะอาดมาด้วยหรือเปล่า?',
  'faq-q2': 'บริษัทการันตีการให้บริการหรือเปล่า?',
  'faq-q3': 'ลูกค้าสามารถให้แม่บ้านออกไปซื้อของได้หรือเปล่า?',
  'faq-q4': 'ขั้นต่ำในการเรียกใช้บริการกี่ชั่วโมง?',
  'faq-q5': 'ถ้าผู้ช่วยมาสาย สามารถต่อเวลาให้ครบที่กำหนดได้หรือไม่?',
  'faq-q6': 'ลูกค้าสามารถจ้างผู้ช่วยของเราต่อได้หรือไม่?',
  'faq-q7': 'ลูกค้าต้องกำหนดสิ่งที่ต้องทำหรือไม่?',
  'faq-q8': 'ลูกค้าสามารถปล่อยให้ผู้ช่วยทำงานตามลำพังได้หรือไม่?',
  'faq-q9': 'ถ้าเกิดมีปัญหาเกิดขึ้น จะต้องทำอย่างไร?',
  'faq-q10': 'บริษัทมีหลักฐานยืนยันตัวตนผู้ช่วยแต่ละคนหรือไม่?',
  'faq-q11': 'ลูกค้าสามารถจ้างโดยตรงได้หรือไม่?',
  'faq-a1': 'เราไม่ได้จัดเตรียมอุปกรณ์ทำความสะอาด ลูกค้าต้องจัดเตรียมให้เราค่ะ',
  'faq-a2': 'เรามีการันตี หากลูกค้าไม่พอใจเราสามารถคืนเงินได้ค่ะ',
  'faq-a3': 'ได้ค่ะ ถ้าหากงานทุกอย่างได้เสร็จเรียบร้อยแล้ว',
  'faq-a4': '2 ชั่วโมงค่ะ',
  'faq-a5': 'ได้ค่ะ',
  'faq-a6': 'ไม่ได้ค่ะ ตารางเวลาไม่สามารถยืดออกไปได้ค่ะ',
  'faq-a7': 'ลูกค้าควรต้องทำค่ะ',
  'faq-a8': 'ได้ค่ะ แต่ลูกค้าต้องแนะนำสิ่งที่ผู้ช่วยต้องทำก่อนค่ะ',
  'faq-a9': 'กรุณาแจ้ง 02-714-2116 / 080-629-6734 เรายินดีที่จะช่วยเหลือทุกปัญหาค่ะ',
  'faq-a10': 'มีค่ะ',
  'faq-a11': 'ไม่ได้ค่ะ เราขอสงวนสิทธิ์ไม่ให้ลูกค้าจ้างได้โดยตรงค่ะ',
  'faq-foot': 'เรายินดีให้ความช่วยเหลือลูกค้าทุกท่าน',
  'alert-schedule-after-hr': 'กรุณาระบุเวลาอย่างน้อย %d ชม. ก่อนกำหนดการ',
  'alert-schedule-after-day': 'กรุณาระบุวันที่อย่างน้อย %d วัน ก่อนกำหนดการ',
  'invite': 'แนะนำเพื่อน',
  'invite-fb': 'facebook',
  'invite-twitter': 'twitter',
  'invite-email': 'อีเมลล์',
  'share-your-link': 'แชร์ลิ้งของคุณ',
  'share-discount': 'เพื่อนของคุณจะได้รับส่วนลด 200฿ ในการใช้บริการครั้งแรก และคุณจะได้รับส่วนลด 200฿ เมื่อเพื่อนของคุณใช้บริการ',
  'share-title': 'ชวนเพื่อนของคุณ!',
  'startup-press': 'เราอยู่บนข่าว',
  'redeem': 'ใช้สิทธิ์โค้ดโปรโมชั่น',
  'description-login': 'เข้าสู่ระบบ Ayasan',
  'description-register': 'สมัครสมาชิก Ayasan',
  'supporter-info-poor': 'น้อย',
  'supporter-info-fair': 'ปานกลาง',
  'supporter-info-good': 'ดี',
  'supporter-info-th': 'พูดไทย',
  'supporter-info-en': 'พูดอังกฤษ',
  'supporter-info-child': 'อายุเด็ก',
  'switch-language': 'เปลี่ยนภาษา',
  'elder-description': 'โปรดอธิบายรายละเอียดผู้สูงอายุ',
  'submenu-more': 'อื่นๆ',
  'no-cleaning-supply': '*กรุณาเตรียมอุปกรณ์ทำความสะอาด',
  'requested_helper_name': 'ชื่อผู้ช่วย',
  'requested_helper': 'ระบุผู้ช่วย (+100฿)',
  'requested_helper_2': 'ผู้ช่วยที่ระบุ:',
  'see-all-services': 'ดูบริการทั้งหมด',
  'all-services': 'บริการทั้งหมด',
  'new': 'ใหม่',
  'month': 'เดือน',
  'recommend': 'แนะนำ',
  'include-transportation': 'รวมค่าเดินทาง',
  'ondemand-service': 'เรียกใช้บริการทันที',
  'maid-subscription-service': 'แพคเกจบริการแม่บ้าน',
  'request-when-needed': 'เรียกใช้บริการตามเวลาที่ต้องการ',
  'book-subscription': 'จองบริการ',
  'new-service': 'บริการใหม่!',
  'new-service-description': 'ลองใช้ Package บริการแม่บ้านได้แล้ว วันนี้',
  'name': 'ชื่อ',
  'package': 'แพกเกจ',
  'send-email': 'ส่งคำขอผ่านอีเมล',
  'subscription-success': 'ขอบคุณที่สนใจบริการของเรา\nเราได้รับคำขอจากคุณแล้ว เราจะติดต่อกลับไปในไม่ช้า',
  'reset-password': 'ตั้งรหัสผ่านใหม่',
  'forget-password-detail': 'กรุณาระบุอีเมล์ที่สมัครสมาชิกไว้เพื่อตั้งรหัสผ่านใหม่',
  'forget-password-success': 'ส่งอีเมลเรียบร้อย',
  'forget-password-inbox': 'กรุณาไปยัง inbox ของคุณเพื่อยืนยันและเปลี่ยนรหัสผ่าน',
  'payment-method': 'การชำระเงิน',
  'line-pay': 'LINE pay',
  'usage': 'ใช้งานไปแล้ว',
  'remaining': 'เหลืออีก',
  'confirm': 'ยืนยัน',
  'use-package': 'ใช้แพกเกจ',
  'package-remaining': 'แพคเกจของคุณเหลืออีก {hour} ชั่วโมง',
  'need-package': 'ต้องการซื้อแพคเกจรายเดือน?',
  'need-more-package': 'ต้องการซื้อแพคเกจเพิ่ม?',
  'buy-package': 'ซื้อแพคเกจ',
  'use-other': 'ไม่ใช่ตอนนี้',
  'add-payment': 'เพิ่มบัตรเครดิต',
  'add-card': 'เพิ่มบัตร',
  'cancel': 'ยกเลิก',
  'valid-thru': 'วันที่หมดอายุ',
  'your-name': 'ชื่อของคุณ',
  'card-number': 'รหัสบนบัตร',
  'credit-card-error': 'เรากันวงเงินการชำระเงินไปยัง บัตรเครดิต ของคุณไม่ได้ โปรดยืนยันว่ารายละเอียดทั้งหมดถูกต้อง คุณอาจติดต่อธนาคารคุณเพื่อยืนยันว่าเขาไม่ได้สกัดกั้นการชำระเงินของคุณหรือใช้วิธีชำระเงินอื่น',
  'card-expiry': 'บัตรหมดอายุ {month}/{year}',
  'no-payment': 'คุณยังไม่มีช่องทางการชำระเงิน',
  'wrong-card-number': 'กรุณาใส่เลขบัตรให้ถูกต้อง',
  'wrong-valid-thru': 'กรุณาใส่วันที่ให้ถูกต้อง',
  'wrong-cvc': 'กรุณาใส่ cvc ให้ถูกต้อง',
  'required-name': 'กรุณาระบุชื่อ',
  'credit-card': 'บัตรเครดิต',
  'add_payment': 'กรุณาเลือกบัตรเครดิต',
  'setup_card_description': 'คุณสามารถชำระค่าบริการผ่านบัตรเครดิตได้ในหน้าเลือกวิธีชำระเงิน',
  'yes': 'ต้องการ',
  'no': 'ไม่ต้องการ',
  'go-back': 'ย้อนกลับ',
  'package_notify_not_enough': 'คุณเหลือแพคเกจเพียง {hours} ชั่วโมง. กรุณาซื้อแพคเกจเพิ่มหรือเลือกวิธีการชำระเงินช่องทางอื่น',
  'subscription-usage-history': 'ดูประวัติการใช้บริการ',
  'subscription-payment-history': 'ดูประวัติการชำระเงิน',
  'pay': 'ชำระเงิน',
  'status-payment-paid': 'ชำระแล้ว',
  'status-payment-pending': 'กำลังรอชำระเงิน',
  'status-payment-canceled': 'ยกเลิก',
  'package-amount': 'ระยะเวลา',
  'click-setup-card': 'กดเพื่อชำระด้วยบัตร',
  'cancel-request': 'ยกเลิกการจอง',
  'cancel-request-confirmation': 'คุณต้องการยกเลิกการจองนี้?',
  'firstname-error': 'กรุณาใส่ชื่อจริง',
  'lastname-error': 'กรุณาใส่นามสกุล',
  'email-error': 'กรุณาใส่อีเมลล์',
  'password-error': 'กรุณาใส่รหัสผ่าน',
  'support-creditcard': 'บัตรเครดิตที่รองรับ',
}
