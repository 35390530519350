import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import createSentryMiddleware from 'redux-sentry-middleware'
import * as Sentry from '@sentry/browser'

import gaMiddleware from './middlewares/ga'

import reducers from '../reducers/index'
import rootSaga from '../sagas/index'

export default (initialState = {}) => {

  const sagaMiddleware = createSagaMiddleware();

  const middleware = [
    gaMiddleware,
    thunk,
  ]

  let devToolsExtension = f => f
  if (process.env.NODE_ENV === 'development') {
    middleware.push(require('redux-logger').default)
    if (window.devToolsExtension) devToolsExtension = window.devToolsExtension()
  }
  if (process.env.NODE_ENV === 'production') {
    middleware.push(createSentryMiddleware(Sentry))
  }

  middleware.push(sagaMiddleware)

  const enhancer = compose(applyMiddleware(...middleware), devToolsExtension)

  const store = createStore(
    reducers,
    initialState,
    enhancer
  )

  store.sagaTask = sagaMiddleware.run(rootSaga);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default
      store.replaceReducer(nextReducer)
    })
  }

  return store
}
