import * as Constants from '../../actions/constants'

const initialState = {
  loading: false,
  error: null,
  showCreditCardModal: false,
  isLoadingPost: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Constants.SHOW_CREDIT_CARD_FORM:
      return {
        ...state,
        showCreditCardModal: true,
      }
    case Constants.HIDE_CREDIT_CARD_FORM:
      return {
        ...state,
        showCreditCardModal: false,
      }
    case Constants.CREDIT_CARDS_GET:
    case Constants.CREDIT_CARD_SELECT:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case Constants.CREDIT_CARDS_GET_SUCCESS:
    case Constants.CREDIT_CARD_SELECT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case Constants.CREDIT_CARDS_GET_FAILED:
    case Constants.CREDIT_CARD_SELECT_FAILED:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case Constants.CREDIT_CARD_POST:
      return {
        ...state,
        isLoadingPost: true,
        error: null,
      }
    case Constants.CREDIT_CARD_POST_SUCCESS:
      return {
        ...state,
        isLoadingPost: false,
      }
    case Constants.CREDIT_CARD_POST_FAILED:
      return {
        ...state,
        isLoadingPost: false,
        error: action.err,
      }
    default: return state
  }
}
