const Parse = require('parse')

export function initializeParse() {
  Parse.initialize(process.env.REACT_APP_API_ID)
  Parse.serverURL = process.env.REACT_APP_API_ENDPOINT
  if (Parse.User.current()) {
    return Promise.resolve(Parse.User.current().toJSON())
  } else {
    return Promise.reject()
  }
}

export function initFacebook() {
  Parse.FacebookUtils.init({
    appId: process.env.REACT_APP_FACEBOOK_ID,
    cookie: true,
    xfbml: true,
    version: 'v2.12',
  })
}

export function fromSession(session) {
  return Parse.User.become(session).then(user => {
    return user.get('customer_id')
      .fetch()
      .then(customer => {
        if (customer.get('default_creditcard_id')) {
          return customer.get('default_creditcard_id').fetch().then(() => Promise.resolve(customer))
        }
        return Promise.resolve(customer)
      })
      .then(customer => {
        return Promise.all([
          Promise.resolve(user),
          Promise.resolve(customer),
        ])
      })
  }).then(res => {
    return res[0]
  }).then(resolveObject, rejectObject)
}

function createCustomer(user) {
  const Customer = Parse.Object.extend('Customer')
  const customer = new Customer()
  customer.set('user_id', user)
  return customer.save().then(customer => {
    user.set('customer_id', customer)
    return user.save()
  })
}

export function registerUser(email, password, firstname, lastname) {
  if (!Parse.User.current()) {
    const user = new Parse.User()
    user.set('username', email.toLowerCase())
    user.set('email', email.toLowerCase())
    user.set('password', password)
    user.set('first_name', firstname)
    user.set('last_name', lastname)
    return user.signUp().then(user => {
      return createCustomer(user)
    }).then(resolveObject, rejectObject)
  } else {
    const user = Parse.User.current()
    user.set('username', email.toLowerCase())
    user.set('email', email.toLowerCase())
    user.set('password', password)
    user.set('first_name', firstname)
    user.set('last_name', lastname)
    return user.save().then(user => {
      return createCustomer(user)
    }).then(resolveObject, rejectObject)
  }
}

export function loginCustomer(email, password) {
  return Parse.User.logIn(email.toLowerCase(), password).then(user => {
    return user.get('customer_id')
      .fetch()
      .then(customer => {
        if (customer.get('default_creditcard_id')) {
          return customer.get('default_creditcard_id').fetch().then(() => Promise.resolve(customer))
        }
        return Promise.resolve(customer)
      })
      .then(customer => {
        return Promise.all([
          Promise.resolve(user),
          Promise.resolve(customer),
        ])
      })
  }).then(res => {
    return res[0]
  }).then(resolveObject, rejectObject)
}

export function loginCustomerFacebook(){
  return Parse.FacebookUtils.logIn('public_profile, email').then(user => {
    if (user.get('customer_id')) {
      return Promise.all([
        Promise.resolve(user),
        user.get('customer_id').fetch(),
      ]).then(res => {
        return res[0]
      }).then(resolveObject, rejectObject)
    } else {
      return getFacebookUserInfo().then(res => {
        const email = res.email || ''
        if (email) {
          user.set('email', email.toLowerCase())
          user.set('username', email.toLowerCase())
        }
        user.set('first_name', res.first_name)
        user.set('last_name', res.last_name)
        user.set('profile_image_url', getFacebookImageUrl(res.id))
        return user.save()
      }).then(resolveObject, rejectObject)
    }
  })
}

export function saveUserInfo(email, firstname, lastname, phone, imageFile) {
  const user = Parse.User.current()
  user.set('first_name', firstname)
  user.set('last_name', lastname)
  user.set('phone_number', phone)
  user.set('email', email)
  if (imageFile) user.set('profile_image_url', imageFile.get('image').url())
  return user.save().then(resolveObject, rejectObject)
}

export function uploadImage(e){
  return new Promise((resolve, reject) => {

    const reader = new FileReader()
    const file = e.target.files[0]

    if (file.type === 'image/jpeg' || file.type === 'image/png') {

      reader.onload = (upload) => {

        let Image = Parse.Object.extend('Image')
        let filename = `image_${new Date().getTime()}.jpg`
        let parseObject = new Image()
        parseObject.set("image", new Parse.File(filename, { base64: upload.target.result }))
        parseObject.save(null, {
          success: object => {
            resolve(parseObject)
          },
          error: (object, error) => {
            reject(error.message)
          }
        })
      }

      reader.readAsDataURL(file)
    } else {
      reject('Invalid file input type')
    }
  })
}

export function savePhone(phone) {
  const user = Parse.User.current()
  user.set('phone_number', phone)
  return user.save().then(resolveObject, rejectObject)
}

export function savePayment(payment) {
  const user = Parse.User.current()
  user.set('payment_method', payment)
  return user.save().then(resolveObject, rejectObject)
}

export function saveAddress(address) {
  const user = Parse.User.current()
  user.set('address_meta', address)
  return user.save().then(resolveObject, rejectObject)
}

export function getJobId(jobId) {
  return new Promise((resolve, reject) => {
    const query = new Parse.Query('Job')
    query.include('supporter_id')
    query.include('promo_id')
    query.include('user_id')
    query.include('charge_id')
    query.get(jobId).then(resolve, reject)
  })
}

export function useReferralCode(code) {
  return Parse.Cloud.run('applyReferral', { code }).then(resolveObject, rejectObject)
}

export function applyPromo(code, job_type) {
  return Parse.Cloud.run('applyPromo', {
    code,
    job_type,
  }).then(resolveObject, rejectObject)
}

export function findPromo(jobType) {
  const query = new Parse.Query('PromoActivity')
  const innerQuery = new Parse.Query('Promo')
  innerQuery.matches('job_type', `.*${jobType}.*`)
  innerQuery.equalTo('active', true)
  query.equalTo('user_id', Parse.User.current())
  query.equalTo('active', true)
  query.greaterThanOrEqualTo('expire_date', new Date())
  query.matchesQuery('promo_id', innerQuery)
  query.ascending('createdAt')
  query.include('promo_id')
  return query.first().then(resolveObject, rejectObject)
}

export function createReview(text, rating, jobObject) {
  const review = fromClass('Review')
  if (text) review.set('text', text)
  review.set('job_id', jobObject)
  review.set('supporter_id', jobObject.get('supporter_id'))
  review.set('user_id', Parse.User.current())
  review.set('rating', rating)
  return review.save().then(resolveObject, rejectObject)
}

export function jobRequest(jobType, subscriptionId, payload) {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('jobRequest', {
      job_type: jobType,
      subscription_id: subscriptionId,
      ...payload
    }).then(resolve, reject)
  })
}

export function getWaitForReviewJob() {
  return new Promise((resolve, reject) => {
    let query = new Parse.Query('Job')
    query.equalTo('user_id', Parse.User.current())
    query.equalTo('status', 'done')
    query.equalTo('customer_review', false)
    query.include('supporter_id')
    query.containedIn('job_type', ['maid', 'elder', 'nanny', 'ac', 'repairer', 'dogsitter'])
    query.first().then(resolve, reject)
  })
}

export function getReviewsOfSupporter(supporterId, limit = 20) {
  return new Promise((resolve, reject) => {
    const query = new Parse.Query('Review')
    query.equalTo('supporter_id', fromClass('Supporter', supporterId))
    query.include('user_id')
    query.greaterThan('rating', 2)
    query.notContainedIn('text', ['', undefined, null])
    query.limit(limit)
    query.ascending('createdAt')
    query.find().then(resolve, reject)
  })
}

export function getCustomerActiveJob() {
  return new Promise((resolve, reject) => {
    const query = new Parse.Query('Job')
    query.equalTo('user_id', Parse.User.current())
    query.containedIn('status', ['waiting', 'match'])
    query.descending('createdAt')
    query.include('promo_id')
    query.include('supporter_id')
    query.include('charge_id')
    query.include('charge_id.credit_card_id')
    query.find().then(resolve, reject)
  })
}

export function getCustomerActiveJobCount() {
  return new Promise((resolve, reject) => {
    const query = new Parse.Query('Job')
    query.equalTo('user_id', Parse.User.current())
    query.containedIn('status', ['waiting', 'match'])
    query.count().then(resolve, reject)
  })
}

export function getCustomerDoneJob(page) {
  return new Promise((resolve, reject) => {
    const query = new Parse.Query('Job')
    query.equalTo('user_id', Parse.User.current())
    query.containedIn('status', ['done', 'cancel'])
    query.descending('createdAt')
    query.include('promo_id')
    query.include('supporter_id')
    query.include('charge_id')
    query.include('charge_id.credit_card_id')
    query.skip(10 * page);
    query.limit(10)
    query.find().then(resolve, reject)
  })
}

export function requestSubscription(package_list_id, month, creditCard){
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('createSubscriptionRequest', {
      package_list_id,
      payment: {
        month,
        type: creditCard ? 'credit_card' : 'cash',
      }
    }).then(resolve, reject)
  })
}

export function getSubscriptionStatus() {
  return new Promise((resolve, reject) => {
    const query = new Parse.Query('Subscription')
    query.equalTo('user_id', Parse.User.current())
    query.find().then(resolve, reject)
  })
}

export function requestPasswordReset(email) {
  return Parse.User.requestPasswordReset(email)
}

export function customerJobCancel(jobId) {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('customerJobCancel', { job_id: jobId }).then(resolve, reject)
  })
}

export function postCreditCard(token) {
  return Parse.Cloud.run('postCreditCard', { token }).then(resolveObject, rejectObject)
}

export function selectCreditCard(credit_card_id) {
  return Parse.Cloud.run('selectDefaultCreditCard', { credit_card_id }).then(resolveObject, rejectObject)
}

export function getChargesSubscription() {
  const query = new Parse.Query('Job')
  query.equalTo('user_id', Parse.User.current())
  const innerQuery = new Parse.Query('Charge')
  innerQuery.equalTo('type', 'subscription')
  innerQuery.equalTo('status', 'success')

  query.matchesQuery('charge_id', innerQuery)
  return query.find().then(resolveArray, rejectObject)
}

export function getSubscriptionRequest() {
  const query = new Parse.Query('SubscriptionRequest')
  query.equalTo('user_id', Parse.User.current())
  query.descending('createdAt')
  return query.find().then(resolveArray, rejectObject)
}

export function getPackageList() {
  const query = new Parse.Query('PackageList')
  query.ascending('price')
  return query.find().then(resolveArray, rejectObject)
}

export function getCreditCard(id) {
  const query = new Parse.Query('CreditCard')
  return query.get(id).then(resolveObject, rejectObject)
}

export function getCreditCards() {
  const query = new Parse.Query('CreditCard')
  query.equalTo('user_id', Parse.User.current())
  return query.find().then(resolveArray, rejectObject)
}

export function logout() {
  return Parse.User.logOut()
}

// function responseArray(resolve, reject) {
//   return {
//     success: results => {
//       resolve(results.map(object => object.toJSON()))
//     },
//     error: (object, error) => {
//       reject(error.message)
//     },
//   }
// }

// function responseObject(resolve, reject, extraObject = {}) {
//   return {
//     success: (object) => {
//       const result = object ? object.toJSON() : {}
//       resolve({ ...extraObject, ...result })
//     },
//     error: (object, error) => {
//       reject(error.message)
//     },
//   }
// }

function resolveObject(object) {
  return Promise.resolve(object ? object.toJSON() : undefined)
}

function resolveArray(results) {
  return Promise.resolve(results.map(object => object.toJSON()))
}

function rejectObject(error) {
  return Promise.reject(error.message)
}

function fromClass(className, id) {
  const Obj = Parse.Object.extend(className)
  const object = new Obj()
  object.id = id
  return object
}

function getFacebookImageUrl(id) {
  return `https://graph.facebook.com/${id}/picture?type=large`
}

function getFacebookUserInfo() {
  const FB = window.FB
  return new Promise(resolve => {
    FB.api('/me', {
      locale: 'en_US',
      fields: 'id, first_name, last_name, email',
    }, resolve)
  })
}
