import { combineReducers } from 'redux'
import { RECEIVE_SUBSCRIPTIONS, RECEIVE_SUBSCRIPTION } from '../actions/constants'

export default combineReducers({
  allIds,
  byId,
})

function allIds(state = [], action = {}) {
  switch (action.type) {
    case RECEIVE_SUBSCRIPTION:
      if (!state.includes(action.payload.id)) {
        return [...state, action.payload.id]
      }
      return state
    case RECEIVE_SUBSCRIPTIONS:
      const array = [...state]
      action.payload.forEach(subscription => {
        if (!array.includes(subscription.id)) {
          array.push(subscription.id)
        }
      })
      return array
    default:
      return state
  }
}

function byId(state = {}, action = {}) {
  switch (action.type) {
    case RECEIVE_SUBSCRIPTION:
      return Object.assign({}, state, {
        [action.payload.id]: action.payload
      })
    case RECEIVE_SUBSCRIPTIONS:
      return Object.assign({}, state,
        action.payload.reduce((obj, subscription) => {
          obj[subscription.id] = subscription
          return obj
        }, {})
      )
    default:
      return state
  }
}
